/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Button, useMediaQuery, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { unsubscribePremium } from '../../reducers/payment';
import { APP_CONFIG } from '@constants';
import cn from 'classnames';
import Backdrop from '@material-ui/core/Backdrop';
import { getContentPath } from '../../helpers/utils';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    btns: {
        width: '100%',
        gap: '5%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "space-between",
        marginTop: '3vh',
        [theme.breakpoints.down('1024')]: {
            marginTop: '0vh',
            marginBottom: '5vh',
        },
    },
    confirmBtn: {
        borderRadius: '25px',
        backgroundColor:theme.palette.selectedTxtClr,
        '&:hover': {
            backgroundColor:theme.palette.selectedTxtClr,
        },
        color: theme.palette.titleClr,
        cursor: 'pointer',
        fontSize: '1.125rem',
        textTransform: 'none',
        width: '80%',
        maxWidth: '300px',
        margin: 'auto',
        height: '45px',
        textTransform: 'capitalize',
        fontWeight: '300',
    },
    cancelBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        color: '#959595',
        fontSize: '1.125rem',
        backgroundColor: '#0000',
        '&:hover': {
            backgroundColor: '#0000',
        },
        width: '100%',
        height: '50px',
        textTransform: 'capitalize',
        fontWeight: '300',
    },
    backDashButton: {
        marginTop: '15px',
        marginBottom: '20px',
    },
    songImg: {
        objectFit: 'cover',
        [theme.breakpoints.up('1024')]: {
            height: '10vw',
            width: '24vw',
            marginTop: '15px',
            borderRadius: '8px',
        },
        [theme.breakpoints.down('1024')]: {
            marginTop: '30px',
            height: '15vh',
            width: '75vw',
            borderRadius: '8px',
        },
    },
    title: {
        color: '#dcdcdc',
        display: 'flex',
        justifyContent: 'center',
        fontSize: '1.25rem',
        paddingTop: '50px',
        [theme.breakpoints.up('1024')]: {
            paddingTop: '15px'
        },
    },
    subTitle: {
        color: '#dcdcdc',
        fontSize: '0.75rem',
        display: 'flex',
        justifyContent: 'flex-start',
        [theme.breakpoints.up('1024')]: {
            paddingRight: '21vw',
            paddingTop: '10px',
            marginBottom: '10px'
        },
        [theme.breakpoints.down('1024')]: {
            paddingLeft: '20px',
            paddingTop: '60px',
        },
    },
    body: {
        color: '#dcdcdc',
        fontSize: '0.875rem',
        lineHeight: '28px',
        [theme.breakpoints.down('1024')]: {
            padding: '15px 20px 50px 20px',
        },
    },
    confirmDiv: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    loaderbackdrop: {
        zIndex: 10000
    },
    circleLoader: {
        color: theme.palette.selectedTxtClr,
    },
    checkIcon: {
        color: theme.palette.selectedTxtClr,
        fontSize: '1.188rem',
        marginTop: '3vh',
        fontWeight: 900 
    }
    
}));

const Unsubscribe = (props) => {
    const classes = useStyles();
    const [confirm, setConfirm] = useState(true);
    const [loader, setLoader] = useState(false);
    const artistDetails = window.sessionStorage.getItem('artistDetails') && JSON.parse(window.sessionStorage.getItem('artistDetails'));

    let premiumImage;
    if (APP_CONFIG.artistDetails) {
        premiumImage = APP_CONFIG.artistDetails.properties.premiumSubscribeImg;
    }

    const handleConfirm = () => {
        if (window.localStorage.getItem('phone') && window.localStorage.getItem('countryCode') &&
            props.subscriptionData?.responseList[0]?.subscriptionId) {
            setLoader(true);
            const mobNm = window.localStorage.getItem('phone');
            const serviceUrl = window.location.href;
            props.unsubscribePremium(encodeURIComponent(mobNm), props.subscriptionData?.responseList[0]?.subscriptionId, 'Not required', serviceUrl).then(() => {
                setLoader(false);
                setConfirm(false);
            }).catch((error) => {
                setLoader(false);
                props.showNotification(error.errorMessage, 'error');
            });
        }
    }

    return (
        <>
            <div>
                {confirm && (<>
                    <div className={classes.title}>
                        <b>{props.UnsubscribeData.title}</b>
                    </div>
                    {/* <div className={classes.subTitle}>
                        {props.UnsubscribeData.subTitle}
                    </div> */}
                    <div className={classes.body}>
                        {props.UnsubscribeData.body}
                    </div>
                    <div className={classes.btns}>
                        <Button
                            className={classes.confirmBtn}
                            variant="contained"
                            color="secondary"
                            onClick={handleConfirm}
                        >
                            <b>CONFIRM</b>
                        </Button>
                        <div
                            className={classes.cancelBtn}
                            variant="contained"
                            onClick={props.handleNoThanks}
                        >
                            <b>NO THANKS</b>
                        </div>
                    </div>
                </>)}

                {!confirm && (
                    <div className={classes.confirmDiv}>
                        <div>
                            <img className={classes.songImg} src={getContentPath(premiumImage)} alt="img" />
                        </div>
                        <div className={classes.checkIcon}>
                            <CheckCircleIcon style={{ marginRight: '10px', fontSize: '1.8rem', marginBottom: '5px' }} />Cancellation Successful
                        </div>
                        <div style={{ color: '#FFFFFF', fontSize: '0.75rem', padding: '8px 20px 40px 20px' }}>{props.UnsubscribeData.UnsubscriptionText}</div>
                        <Button
                            className={cn(classes.confirmBtn, classes.backDashButton)}
                            variant="contained"
                            onClick={props.handleBackToDash}
                        >
                            <b>Go to Homepage</b>
                        </Button>
                    </div>)}
                    <Backdrop className={classes.loaderbackdrop} open={loader}>
                        <CircularProgress className={classes.circleLoader} />
                    </Backdrop>
            </div>
        </>
    );
};
const mapStateToProps = state => {
    return {
        subscriptionData: state.payment.subscriptionData
    };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({
    unsubscribePremium
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Unsubscribe));


