/* eslint-disable */
import { Box, Button, makeStyles, useMediaQuery } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Modal from '@material-ui/core/Modal';
import BackArrow from '../../components/BackArrow';
import ProfileHeader from '../../components/ProfileHeader';
import PremierBanner from '../../components/ProfileOptions/PremierBanner';
import ProfileLinks from '../../components/ProfileOptions/ProfileLinks';
import ProfileOptions from '../../components/ProfileOptions/ProfileOptions';
import ProfileDetails from '../../components/ProfileDetails';
import {
  renderEditPanel,
  renderInputPanel,
  renderProfilePanel,
  renderFeedbackPanel,
  renderSelectedCallerTunePanel,
  renderSubscriptionPanel
} from '../../reducers/ui';
import { getContentPath } from '../../helpers/utils';
import EditProfile from '../../components/EditProfile';
import Feedback from '../../components/Feedback';
import PremiumPayment from '../../components/common/PremiumPayment';
import { getUserDetails } from '../../reducers/otp';
import { updateInitialUserdetails } from '../../reducers/profile';
import { saveconsentLog } from '../../reducers/payment';
import profilePic from '@assets/images/default-profile-pic.jpg';
import SelectedCallerTune from '../../components/Music/SelectedCallerTune';
import CloseIcon from '@material-ui/icons/Close';
import Subscription from '../../components/Subscription';
import NotificationMessage from '../../components/NotificationMessage';
import ErrorModal from '@containers/Modals/ErrorModal';
import { APP_CONFIG } from '@constants';
import { EN } from '@localization';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme) => ({
  desktopLayout: {
    display: 'flex',
    width: '96%',
    marginLeft: 'auto',
    // paddingTop: '60px !important',
    justifyContent: 'flex-end',
    flexGrow: 1,
    height: '100vh',
    background: theme.palette.bgThemeColor,
  },
  desktopFirstPart: {
    width: '60%',
    display: 'flex',
    paddingTop: '20px !important',
    flexDirection: 'column',
  },
  desktopSecondPart: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    background: '#242424',
  },
  button: {
    color: theme.palette.titleClr,
    background: theme.palette.selectedTxtClr,
    borderRadius: 25,
    minWidth: '50%',
    fontWeight: '700',
    padding: '10px',
    marginBottom: '40px',
    fontFamily: 'SegoeUISemiBold',
    textTransform: 'capitalize',
    padding: '15px',
    '&:hover': {
      color: theme.palette.titleClr,
      background: theme.palette.selectedTxtClr,
  },
  },
  paper: {
    margin: 'auto',
    marginTop: '9%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',
    background: theme.palette.bgThemeColor,
    border: `2px solid ${theme.palette.bgThemeColor}`,
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down('1024')]: {
      width: '95%',
      borderRadius: '20px',
    },
  },
  callerTune: {
    margin: 'auto',
    marginTop: '1%',
    '@media (min-height: 720px) and (max-height: 900px)': {
      marginTop: '3%',
    },
    '@media (min-height: 901px) and (max-height: 1080px)': {
      marginTop: '9%',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '30%',
    background: theme.palette.bgThemeColor,
    border: `2px solid ${theme.palette.bgThemeColor}`,
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  loaderbackdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  circleLoader: {
    color: theme.palette.selectedTxtClr,
  }
}));

const Profile = props => {
  const [premiumSubscribe, setPremiumSubscribe] = useState(true);
  const [premiumPayment, setPremiumPayment] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);

  const [openNoti, setOpenNoti] = useState(false);
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');
  const [loader, setLoader] = useState(false);

  const [openError, setOpenError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorHeading, setErrorHeading] = useState('');

  const [profileImg, setProfileImg] = useState('');
  const artistDetails = window.sessionStorage.getItem('artistDetails') && JSON.parse(window.sessionStorage.getItem('artistDetails'));

  let premiumImage;
  if (APP_CONFIG.artistDetails) {
    premiumImage = APP_CONFIG.artistDetails.properties.premiumSubscribeImg;
  }

  useEffect(() => {
    setLoader(true);
    setProfileImg('');
    props.getUserDetails().then((userDetails) => {
      console.log("user data ==>", userDetails);
      setProfileImg(userDetails?.responseList[0]?.profileImgUrl);
      setLoader(false);
      props.updateInitialUserdetails(userDetails.responseList[0]);
      window.localStorage.setItem('userDetails', userDetails.responseList[0].userSurrogateId);
      window.localStorage.setItem('phone', userDetails.responseList[0].mobileNumber ? userDetails.responseList[0].mobileNumber : '');
      window.localStorage.setItem('countryCode', userDetails.responseList[0].mobileNumberCountryCode ? userDetails.responseList[0].mobileNumberCountryCode : '');
      window.localStorage.setItem('name', userDetails.responseList[0].name ? userDetails.responseList[0].name : '');
      window.localStorage.setItem('email', userDetails.responseList[0].email ? userDetails.responseList[0].email : '');
      window.localStorage.setItem('dob', userDetails.responseList[0].dob ? userDetails.responseList[0].dob : '');
    });
  }, [props.profilePanel]);

  const matches = useMediaQuery('(max-width:1020px)');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    props.renderInputPanel(false);
    props.renderEditPanel(false);
    props.renderProfilePanel(true);
    props.renderFeedbackPanel(false);
    props.renderSelectedCallerTunePanel(false);
    props.renderSubscriptionPanel(false);
  }, []);

  useEffect(() => {
    if (matches) {
      if (props.feedbackPanel) { props.renderProfilePanel(false) }
      else { props.renderProfilePanel(true) }
    }
    else { props.renderProfilePanel(true) }
    setOpen(true);
  }, [props.feedbackPanel]);

  useEffect(() => {
    if (matches) {
      if (props.callerTuneListPanel) { props.renderProfilePanel(false) }
      else { props.renderProfilePanel(true) }
    }
    else { props.renderProfilePanel(true) }
    setOpen(true);
  }, [props.callerTuneListPanel]);


  useEffect(() => {
    if (matches) {
      if (props.showSubscriptionPanel) { props.renderProfilePanel(false) }
      else { props.renderProfilePanel(true) }
    }
    else { props.renderProfilePanel(true) }
    setOpen(true);
  }, [props.showSubscriptionPanel]);

  const classes = useStyles();

  const redirectToProfile = () => {
    props.renderInputPanel(false);
    props.renderEditPanel(false);
    props.renderProfilePanel(true);
    props.renderSelectedCallerTunePanel(false);
    props.renderSubscriptionPanel(false);
  };

  // const body = (
  //   <div className={classes.paper}>
  //     <Feedback />
  //   </div>
  // );

  const handleClose = () => {
    setOpen(false);
    props.renderFeedbackPanel(false);
    props.renderSelectedCallerTunePanel(false);
    props.renderSubscriptionPanel(false);
    // props.renderProfilePanel(true);
  };
  const handleSelectedCallerTune = () => {
    setOpen(false);
    props.renderFeedbackPanel(false);
    props.renderSelectedCallerTunePanel(false);
  }

  const handleSubscription = () => {
    setOpen(false);
    props.renderFeedbackPanel(false);
    props.renderSelectedCallerTunePanel(false);
    props.renderSubscriptionPanel(false);
  }

  const handlePaymentClose = () => {
    setOpenPayment(false);
    setPremiumPayment(false);
    setPremiumSubscribe(false);
  }

  const redirectToPremiumPayment = () => {
    setPremiumPayment(true);
  }

  const handleClosePremiumSubscribe = () => {
    setPremiumSubscribe(false);
    setPremiumPayment(true);
  }

  const getAcrOrMob = () => {
    let acrOrMob = '';
    if (window.localStorage.getItem('phone') && window.localStorage.getItem('countryCode')) {
      acrOrMob = window.localStorage.getItem('phone');
    } else if (window.sessionStorage.getItem('is_he_user') === "true" && window.sessionStorage.getItem('he_acr_value')) {
      acrOrMob = window.sessionStorage.getItem('he_acr_value');
    }
    return acrOrMob;
  }

  const handleChoosePremiumSubscribe = () => {
    setPremiumSubscribe(false);
    if (props.isUserLoggedIn) {
      let acrOrMob = getAcrOrMob();
      //log consent
      if (acrOrMob) {
        const serviceUrl = window.location.href;
        props.saveconsentLog(encodeURIComponent(acrOrMob), EN.CONSENT_LOG_ARTIST_PORTAL_SERVICE, serviceUrl, EN.FIRST_CONSENT_APPROVAL);
      }
    }
  }

  const showNotification = (message, msgType) => {
    setOpenNoti(true);
    setMsg(message);
    setMsgType(msgType);
  }

  const handleCloseForNotify = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenNoti(false);
  };
  return (
    <>
      {matches ? (
        <div style={{ background: '#242424' }}>
          {props.profilePanel && (
            <>
              <BackArrow />
              <ProfileHeader thumbPath={profileImg && profileImg} />

              <ProfileOptions />
              {!props.serviceOffers?.responseList[0]?.status && (<div style={{ width: '90%', margin: '0 auto 0 auto', cursor: 'pointer' }} onClick={() => setOpenPayment(true)}><PremierBanner /></div>)}
              {props.serviceOffers?.responseList[0]?.status === '4' && (<div style={{ width: '90%', margin: '100px auto 0 auto', color: '#f44336', textAlign: 'center' }}>{EN.SUSPENDED_MSG}</div>)}
              <ProfileLinks />
              <Backdrop className={classes.loaderbackdrop} open={loader}>
                <CircularProgress className={classes.circleLoader} />
              </Backdrop>
            </>
          )}
          {props.editPanel && (
            <>
              <BackArrow />
              <ProfileDetails thumbPath={(props.userDetails && props.userDetails[0]?.profileImgUrl) && props.userDetails[0]?.profileImgUrl} userDetail={props.userDetails && props.userDetails[0]} />
            </>
          )}
          {props.inputPanel && (
            <>
              <BackArrow />
              <EditProfile initialValues={props.initialUserDetails} thumbPath={(props.userDetails && props.userDetails[0].profileImgUrl) && props.userDetails[0].profileImgUrl} />
            </>
          )}
          {props.feedbackPanel && (
            <div>
              <Modal
                open={open}
                onClose={handleClose}
                BackdropProps={{
                  style: {
                    backgroundColor: '#707070',
                    opacity: '80%',
                    boxShadow: 'none',
                  },
                }}
              >
                <div className={classes.paper}>
                  <Feedback />
                </div>
              </Modal>
            </div>
          )}
          {props.callerTuneListPanel && (
            <div style={{ height: '100vh' }}>
              <BackArrow />
              <SelectedCallerTune />
            </div>
          )}

          {props.showSubscriptionPanel && (
            <div style={{ height: '100vh' }}>
              <div style={{ display: 'inline-block' }} onClick={handleSubscription} role="button" tabIndex={0} onKeyUp={handleClose}>
                <BackArrow />
              </div>
              <Subscription showNotification={showNotification} />
            </div>
          )}
        </div>
      ) : (
        <Box className={classes.desktopLayout}>
          <Box className={classes.desktopFirstPart}>
            <p style={{ fontFamily: 'SegoeUISemiBold', fontWeight: '550', color: '#969696', paddingLeft: '20px !important', fontSize: '2.75rem' }}>
              Profile &amp; Settings
            </p>
            <ProfileLinks />
          </Box>
          <div>
            {props.feedbackPanel && (
              <div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  BackdropProps={{
                    style: {
                      backgroundColor: '#707070',
                      opacity: '80%',
                      boxShadow: 'none',
                    },
                  }}
                >
                  <div className={classes.paper}>
                    <Feedback />
                  </div>
                </Modal>
              </div>
            )}
          </div>
          <div>
            {props.callerTuneListPanel && (
              <div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  BackdropProps={{
                    style: {
                      backgroundColor: '#707070',
                      opacity: '80%',
                      boxShadow: 'none',
                    },
                  }}
                >
                  <div className={classes.callerTune}>
                    <CloseIcon style={{ fontSize: '40px', marginLeft: '98%', cursor: 'pointer', color: 'white' }}
                      onClick={handleSelectedCallerTune}
                    />
                    <SelectedCallerTune />
                  </div>
                </Modal>
              </div>
            )}
          </div>

          <div>
            {props.showSubscriptionPanel && (
              <div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  BackdropProps={{
                    style: {
                      backgroundColor: '#707070',
                      opacity: '80%',
                      boxShadow: 'none',
                    },
                  }}
                >
                  <div>
                    <Subscription handleSubscription={handleSubscription} showNotification={showNotification} />
                  </div>
                </Modal>
              </div>
            )}
          </div>

          <Box className={classes.desktopSecondPart}>
            {props.profilePanel && (
              <>
                <ProfileHeader thumbPath={profileImg && profileImg} />

                <ProfileOptions />
                {!props.serviceOffers?.responseList[0]?.status && (<div style={{ width: '90%', margin: '100px auto 0 auto', cursor: 'pointer' }} onClick={() => setOpenPayment(true)}><PremierBanner /></div>)}
                {props.serviceOffers?.responseList[0]?.status === '4' && (<div style={{ width: '90%', margin: '100px auto 0 auto', color: '#f44336', textAlign: 'center' }}>{EN.SUSPENDED_MSG}</div>)}
                <Backdrop className={classes.loaderbackdrop} open={loader}>
                  <CircularProgress className={classes.circleLoader} />
                </Backdrop>
              </>
            )}
            {props.editPanel && (
              <>
                <ProfileDetails thumbPath={(props.userDetails && props.userDetails[0].profileImgUrl) && props.userDetails[0].profileImgUrl} userDetail={props.userDetails && props.userDetails[0]} />
                <Box display="flex" justifyContent="center" alignItems='center'>
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={redirectToProfile}
                  >
                    Go to profile
                  </Button>
                </Box>
              </>
            )}
            {props.inputPanel && <EditProfile initialValues={props.initialUserDetails} thumbPath={(props.userDetails && props.userDetails[0].profileImgUrl) && props.userDetails[0].profileImgUrl} />}
          </Box>
        </Box>
      )}
      {openPayment && <PremiumPayment
        open={openPayment}
        thumbnail={getContentPath(premiumImage)}
        handleClose={handlePaymentClose}
        loginRedirect={redirectToPremiumPayment}
        forSubscribe={premiumSubscribe}
        first={true}
        closePremiumSubscribe={handleClosePremiumSubscribe}
        handlePremiumSubscribe={handleChoosePremiumSubscribe}
        showLoginNotification={showNotification}
        isRedirect={true}
        setOpenError={setOpenError}
        setErrorMsg={setErrorMsg}
        setErrorHeading={setErrorHeading}
      />
      }
      <NotificationMessage open={openNoti} msgType={msgType} msg={msg} handleClose={handleCloseForNotify} />
      <ErrorModal id="profile" openError={openError} setOpenError={setOpenError} errorMsg={errorMsg} errorHeading={errorHeading} />
    </>
  );
};

const mapStateToProps = state => {
  return {
    editPanel: state.ui.showEditPanel,
    inputPanel: state.ui.showInputPanel,
    profilePanel: state.ui.showProfilePanel,
    feedbackPanel: state.ui.showFeedbackPanel,
    userDetails: state.otp.userData,
    callerTuneListPanel: state.ui.showSelectedCallertunePanel,
    initialUserDetails: state.profile.initialUserDetails,
    showSubscriptionPanel: state.ui.showSubscriptionPanel,
    serviceOffers: state.payment.serviceOffers,
    isUserLoggedIn: state.oauth.isUserLoggedIn,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      renderEditPanel,
      renderInputPanel,
      renderProfilePanel,
      renderFeedbackPanel,
      renderSelectedCallerTunePanel,
      getUserDetails,
      updateInitialUserdetails,
      renderSubscriptionPanel,
      saveconsentLog,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
