export const GET_PAYMENT_SERVICE_OFFERS_REQUESTED = 'payment/GET_PAYMENT_SERVICE_OFFERS_REQUESTED';
export const GET_PAYMENT_SERVICE_OFFERS_SUCCESS = 'payment/GET_PAYMENT_SERVICE_OFFERS_SUCCESS';
export const GET_PAYMENT_SERVICE_OFFERS_FAILURE = 'payment/GET_PAYMENT_SERVICE_OFFERS_FAILURE';

export const EVINA_AND_CHARGE_REQUESTED = 'payment/EVINA_AND_CHARGE_REQUESTED';
export const EVINA_AND_CHARGE_SUCCESS = 'payment/EVINA_AND_CHARGE_SUCCESS';
export const EVINA_AND_CHARGE_FAILURE = 'payment/EVINA_AND_CHARGE_FAILURE';

export const GET_SUBSCRIPTION_DETAILS_REQUESTED = 'payment/GET_SUBSCRIPTION_DETAILS_REQUESTED';
export const GET_SUBSCRIPTION_DETAILS_SUCCESS = 'payment/GET_SUBSCRIPTION_DETAILS_SUCCESS';
export const GET_SUBSCRIPTION_DETAILS_FAILURE = 'payment/GET_SUBSCRIPTION_DETAILS_FAILURE';

export const UNSUBSCRIBE_PREMIUM_REQUESTED = 'payment/UNSUBSCRIBE_PREMIUM_REQUESTED';
export const UNSUBSCRIBE_PREMIUM_SUCCESS = 'payment/UNSUBSCRIBE_PREMIUM_SUCCESS';
export const UNSUBSCRIBE_PREMIUM_FAILURE = 'payment/UNSUBSCRIBE_PREMIUM_FAILURE';

export const GET_ACR_VALUE_REQUESTED = 'payment/GET_ACR_VALUE_REQUESTED';
export const GET_ACR_VALUE_SUCCESS = 'payment/GET_ACR_VALUE_SUCCESS';
export const GET_ACR_VALUE_FAILURE = 'payment/GET_ACR_VALUE_FAILURE';

export const CONSENT_LOG_REQUESTED = 'payment/CONSENT_LOG_REQUESTED';
export const CONSENT_LOG_SUCCESS = 'payment/CONSENT_LOG_SUCCESS';
export const CONSENT_LOG_FAILURE = 'payment/CONSENT_LOG_FAILURE';

export const UPDATE_SERVICE_OFFER = 'payment/UPDATE_SERVICE_OFFER';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  serviceOffers: null,
  subscriptionData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_SERVICE_OFFERS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_PAYMENT_SERVICE_OFFERS_SUCCESS: {
      console.log('serviceOffers ==>', action.result);
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        serviceOffers: action.result,
      };
    }
    case GET_PAYMENT_SERVICE_OFFERS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case EVINA_AND_CHARGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case EVINA_AND_CHARGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case EVINA_AND_CHARGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_SUBSCRIPTION_DETAILS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SUBSCRIPTION_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        subscriptionData: action.result,
      };
    }
    case GET_SUBSCRIPTION_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UNSUBSCRIBE_PREMIUM_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UNSUBSCRIBE_PREMIUM_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UNSUBSCRIBE_PREMIUM_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_ACR_VALUE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ACR_VALUE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_ACR_VALUE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case CONSENT_LOG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case CONSENT_LOG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case CONSENT_LOG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_SERVICE_OFFER: {
      const accessToken = window.localStorage.getItem('access_token');
      const userDetails = window.localStorage.getItem('userDetails');
      const allowedServices = window.localStorage.getItem('allowedServices');
      const phone = window.localStorage.getItem('phone');
      const countryCode = window.localStorage.getItem('countryCode');
      let serviceOfferValue = state.serviceOffers;
      if (
        !accessToken
        || accessToken === null
        || accessToken === 'null'
        || accessToken === ''
        || !userDetails
        || userDetails === null
        || userDetails === 'null'
        || userDetails === ''
        || !allowedServices
        || allowedServices === null
        || allowedServices === 'null'
        || allowedServices === []
        || allowedServices === ''
        || !phone
        || phone === null
        || phone === 'null'
        || phone === ''
        || !countryCode
        || countryCode === null
        || countryCode === 'null'
        || countryCode === ''
      ) {
        serviceOfferValue = null;
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        serviceOffers: serviceOfferValue,
      };
    }
    default:
      return state;
  }
};

export const getPaymentServiceOffers = (msisdn, channel, serviceUrl) => {
  let channelStr = '';
  if (channel) {
    channelStr = `&channel=${channel}`;
  }
  return {
    types: [GET_PAYMENT_SERVICE_OFFERS_REQUESTED, GET_PAYMENT_SERVICE_OFFERS_SUCCESS, GET_PAYMENT_SERVICE_OFFERS_FAILURE],
    promise: (client) => client.get(`getPaymentServiceOffers?msisdn=${msisdn}${channelStr}&serviceUrl=${encodeURIComponent(serviceUrl)}`)
  };
};

export const updateServiceOffers = () => {
  return {
    type: UPDATE_SERVICE_OFFER,
  };
};

export const evinaAndCharge = (msisdn, longPackageId, shortPackageId, channel, serviceUrl) => {
  let channelStr = '';
  if (channel) {
    channelStr = `&channel=${channel}`;
  }
  return {
    types: [EVINA_AND_CHARGE_REQUESTED, EVINA_AND_CHARGE_SUCCESS, EVINA_AND_CHARGE_FAILURE],
    promise: (client) => client.post(`evinaAndCharge?msisdn=${msisdn}&longPackageId=${longPackageId}&shortPackageId=${shortPackageId}${channelStr}&serviceUrl=${encodeURIComponent(serviceUrl)}`, {
    })
  };
};

export const getSubscriptionDetails = () => {
  return {
    types: [
      GET_SUBSCRIPTION_DETAILS_REQUESTED,
      GET_SUBSCRIPTION_DETAILS_SUCCESS,
      GET_SUBSCRIPTION_DETAILS_FAILURE,
    ],
    promise: (client) => client.get('getSubscriptionDetails'),
  };
};

export const unsubscribePremium = (msisdn, subscriptionId, reason, serviceUrl) => {
  return {
    types: [UNSUBSCRIBE_PREMIUM_REQUESTED, UNSUBSCRIBE_PREMIUM_SUCCESS, UNSUBSCRIBE_PREMIUM_FAILURE],
    promise: (client) => client.del(`unsubscribePremium?msisdn=${msisdn}&subscriptionId=${subscriptionId}&reason=${reason}&serviceUrl=${encodeURIComponent(serviceUrl)}`, {
    })
  };
};

export const getAcrValue = (transId) => {
  return {
    types: [
      GET_ACR_VALUE_REQUESTED,
      GET_ACR_VALUE_SUCCESS,
      GET_ACR_VALUE_FAILURE,
    ],
    promise: (client) => client.get(`getAcrValue?transId=${transId}`),
  };
};

export const saveconsentLog = (msisdn, service, serviceUrl, message) => {
  return {
    types: [CONSENT_LOG_REQUESTED, CONSENT_LOG_SUCCESS, CONSENT_LOG_FAILURE],
    promise: (client) => client.post(`saveConsentLog?msisdn=${msisdn}&serviceUrl=${encodeURIComponent(serviceUrl)}&service=${service}&message=${message}`, {
    })
  };
};
