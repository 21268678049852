/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Button, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CardSubscription from './CardSubscription';
import PremiumIcon from '@assets/images/PremiumIcon.svg';
import { Avatar } from '@material-ui/core';
import Unsubscribe from './Unsubscribe';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles((theme) => ({
    topDesk: {

    },
    top: {
        background: theme.palette.bgThemeColor,
        borderRadius: '10px',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        background: '#242424',
        position: 'relative',
        [theme.breakpoints.up('1024')]: {
            margin: 'auto',
            backgroundColor: theme.palette.bgThemeColor,
            border: `2px solid ${theme.palette.bgThemeColor}`,
            borderRadius: '10px',
            justifyContent: 'flex-end',
            width: '30%',
            marginTop: '1%',
            '@media (min-height: 720px) and (max-height: 900px)': {
                marginTop: '3%',
            },
            '@media (min-height: 901px) and (max-height: 1080px)': {
                marginTop: '9%',
            },
        },
    },
    img: {
        clipPath: 'circle(80%)',
        shapeOutside: 'circle(80%)',
        height: '50%',
        width: '50%',
        [theme.breakpoints.down('1024')]: {
            height: '90%',
            width: '40%',
        },
    },
    ActiveText: {
        color: theme.palette.selectedTxtClr,
        fontSize: '1.25rem',
        marginBottom: '35px',
        marginTop: '10px'
    },

    CardSubscription: {
        width: '90%',
        height: '400px',
        overflowY: 'scroll',
        [theme.breakpoints.up('1024')]: {
            width: '30vw',
            maxHeight: '245px',
        },
    },
    iconStyle: {
        color: theme.palette.selectedTxtClr,
        width: '1.8em',
        height: '1.8em',
    },
    options: {
        margin: 'auto',
        height: '6em',
        width: '6em',

        color: '#EE255C',
        background: '#484848',
        boxShadow: '5px 3px 9px 2px rgba(0,0,0,0.15)',
        [theme.breakpoints.between('1024', '1410')]: {
            height: '5.5em',
            width: '5.5em',
        },
        [theme.breakpoints.between('1410', '1610')]: {
            height: '6em',
            width: '6em',
        },

    },

    optionStyle: {
        margin: 'auto',
        height: '3em',
        width: '3em',

        color: theme.palette.selectedTxtClr,
        background: theme.palette.bgThemeColor,
        boxShadow: '5px 3px 9px 2px rgba(0,0,0,0.15)',
        [theme.breakpoints.down('sm')]: {
            boxShadow: '#EE255C 0px 0px 15px 0px',
        },
        [theme.breakpoints.up('sm')]: {
            height: '3.5em',
            width: '3.5em',
        },
    },
    buttonStyle: {
        color: theme.palette.selectedTxtClr,
        background: '#484848',
        '&:hover': {
            backgroundColor: '#484848',
        },
        borderRadius: 25,
        fontSize: '1rem',
        width: '25vw',
        height: '6vh',
        fontWeight: '900',
        marginTop: '20px',
        marginBottom: '20px',
        textTransform: 'capitalize',
        [theme.breakpoints.down('1024')]: {
            width: '80vw',
            marginTop: '5px',
            marginBottom: '20px'

        },

    },
    close: {
        fontSize: '40px',
        marginLeft: '83%',
        marginTop: '2%',
        cursor: 'pointer',
        color: theme.palette.titleClr
    },
    topUnsubscribeMob: {
        margin: 'auto',
        marginTop: 'calc(89vh - 585px)',
        width: '95%',
        borderRadius: '10px',
        background: `radial-gradient(#113d44, ${theme.palette.bgThemeColor})`,
        // background: theme.palette.bgThemeColor,
        borderRadius: '20px',
        height: 'auto',
        minHeight: '40vh',
        backdropFilter: 'blur(10px)',
    },
    topUnsubscribeDesk: {
        margin: 'auto',
        marginTop: '1%',
        '@media (min-height: 720px) and (max-height: 900px)': {
            marginTop: '3%',
        },
        '@media (min-height: 901px) and (max-height: 1080px)': {
            marginTop: '9%',
        },
        width: '30%',
        background: `radial-gradient(#113d44,${theme.palette.bgThemeColor})`,
        border: `2px solid ${theme.palette.bgThemeColor}`,
        borderRadius: '10px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 0),
    }
}));

const UnsubscribeData = {

    title: 'Cancel Your Subscription',
    // subTitle: 'Details',
    body: window.sessionStorage.getItem('artistDetails') ? ('"' + JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.unsubscribeMsg + '"') : '" "',
    UnsubscriptionText: window.sessionStorage.getItem('artistDetails') ? JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.unsubscribeConfirmationMsg : ''
}

const Subscription = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const mobileView = useMediaQuery('(max-width:1024px)');
    const [openUnsubscribe, setOpenUnsubscribe] = useState(false);
    const [open, setOpen] = useState(true);
    const [unsubscribe, setUnsubscribe] = useState(true);

    const handleUnsubscribe = () => {
        setOpenUnsubscribe(true);
        setOpen(true);
        setUnsubscribe(false);
    }

    const handleBackToDash = () => {
        history.push('/homepage');
    }

    const handleClose = () => {
        setOpen(false);
        setOpenUnsubscribe(false);
    }

    const handleNoThanks = () => {
        setOpen(false);
        setOpenUnsubscribe(false);
        setUnsubscribe(true);
    }

    return (
        <>
            <div className={mobileView ? classes.top : classes.topDesk}>
                {(unsubscribe || mobileView) && (<div className={classes.header}>
                    {!mobileView && <CloseIcon className={classes.close} onClick={props.handleSubscription} />}
                    <Avatar className={classes.options}>
                        <img className={classes.iconStyle} src={PremiumIcon} alt='PremiumIcon' />  
                    </Avatar>
                    <div className={classes.ActiveText}><b>Subscription</b>
                    </div>
                    <div className={classes.CardSubscription}>
                        <CardSubscription />
                    </div>
                    { props.subscriptionData?.responseList[0]?.subscriptionId && (
                        <div style={{ display: 'flex', justifyContent: 'center',position:mobileView && 'absolute',top:'82vh'}}>
                            <Button
                                variant="contained"
                                className={classes.buttonStyle}
                                onClick={handleUnsubscribe}
                            >
                                Unsubscribe
                            </Button>
                        </div>
                    )}
                </div>)}
                {(!mobileView && !unsubscribe) && (<div className={classes.topUnsubscribeDesk}>
                    <Unsubscribe handleBackToDash={handleBackToDash} handleNoThanks={handleNoThanks} UnsubscribeData={UnsubscribeData} showNotification={props.showNotification} />
                </div>)}
            </div>
            {mobileView && openUnsubscribe && (
                <Modal
                    open={open}
                    onClose={handleClose}
                    BackdropProps={{
                        style: {
                            backgroundColor: '#707070',
                            opacity: '80%',
                            boxShadow: 'none',
                        },
                    }}
                >
                    <div className={classes.topUnsubscribeMob}>
                        <Unsubscribe handleBackToDash={handleBackToDash} handleNoThanks={handleNoThanks} UnsubscribeData={UnsubscribeData} showNotification={props.showNotification} />
                    </div>
                </Modal>)}
        </>
    );
};

const mapStateToProps = state => {
    return {
        subscriptionData: state.payment.subscriptionData
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Subscription));

