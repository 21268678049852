export const UPDATE_USERDETAILS_REQUESTED = 'profile/UPDATE_USERDETAILS_REQUESTED';
export const UPDATE_USERDETAILS_SUCCESS = 'profile/UPDATE_USERDETAILS_SUCCESS';
export const UPDATE_USERDETAILS_FAILURE = 'profile/UPDATE_USERDETAILS_FAILURE';

export const UPDATE_INITAL_USERDETAILS_SUCCESS = 'profile/UPDATE_INITAL_USERDETAILS_SUCCESS';

export const UPLOAD_ARTIST_DETAILS_REQUESTED = 'profile/UPLOAD_ARTIST_DETAILS_REQUESTED';
export const UPLOAD_ARTIST_DETAILS_SUCCESS = 'profile/UPLOAD_ARTIST_DETAILS_SUCCESS';
export const UPLOAD_ARTIST_DETAILS_FAILURE = 'profile/UPLOAD_ARTIST_DETAILS_FAILURE';

export const ARTIST_INFO_REQUESTED = 'profile/ARTIST_INFO_REQUESTED';
export const ARTIST_INFO_SUCCESS = 'profile/ARTIST_INFO_SUCCESS';
export const ARTIST_INFO_FAILURE = 'profile/ARTIST_INFO_FAILURE';

export const GET_USER_DATA = 'profile/GET_USER_DATA';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  userData: [],
  initialUserDetails: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USERDETAILS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case UPDATE_USERDETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case UPDATE_USERDETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case UPDATE_INITAL_USERDETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        initialUserDetails: action.payload
      };
    }
    case UPLOAD_ARTIST_DETAILS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case UPLOAD_ARTIST_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case UPLOAD_ARTIST_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case ARTIST_INFO_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case ARTIST_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        artistInfo: action.result
      };
    }
    case ARTIST_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    default:
      return state;
  }
};

export const updateUserDetails = (data) => {
  const headers = { has_auth_token: 0 };
  return {
    types: [UPDATE_USERDETAILS_REQUESTED, UPDATE_USERDETAILS_SUCCESS, UPDATE_USERDETAILS_FAILURE],
    promise: (client) => client.put('updateUserDetails', {
      data,
      headers
    })
  };
};

export const updateInitialUserdetails = (payload) => {
  return {
    type: UPDATE_INITAL_USERDETAILS_SUCCESS,
    payload
  };
};
export const uploadArtistDetails = (data) => {
  return {
    types: [UPLOAD_ARTIST_DETAILS_REQUESTED, UPLOAD_ARTIST_DETAILS_SUCCESS, UPLOAD_ARTIST_DETAILS_FAILURE],
    promise: (client) => client.post('uploadArtistDetails', {
      data
    })
  };
};

export const getArtistInfo = (domain) => {
  return {
    types: [ARTIST_INFO_REQUESTED, ARTIST_INFO_SUCCESS, ARTIST_INFO_FAILURE],
    promise: (client) => client.get(`getArtistInfo?domain=${domain}`, {
    })
  };
};
