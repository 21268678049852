/* eslint-disable */
import { Grid, useMediaQuery } from '@material-ui/core';
import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { renderEditPanel, renderInputPanel, renderProfilePanel, renderFeedbackPanel, renderSelectedCallerTunePanel, renderSubscriptionPanel } from '../../reducers/ui';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  arrowStyle :{
    background: '#242424',
    color: theme.palette.selectedTxtClr,
    paddingLeft: '28px',
    paddingTop: '20px',
  }
}))

const BackArrow = props => {
  const classes = useStyles();
  let history = useHistory();
  const mobile = useMediaQuery('(max-width:960px)');

  const handleBackRoute = () => {
    if (mobile && props.profilePanel) {
      history.goBack();
    }

    if (mobile && props.editPanel) {
      props.renderInputPanel(false);
      props.renderEditPanel(false);
      props.renderProfilePanel(true);
      props.renderFeedbackPanel(false);
      props.renderSelectedCallerTunePanel(false);
    }
    if (mobile && props.inputPanel) {
      props.renderInputPanel(false);
      props.renderEditPanel(true);
      props.renderProfilePanel(false);
      props.renderFeedbackPanel(false);
      props.renderSelectedCallerTunePanel(false);
    }
    if (mobile && props.feedbackPanel) {
      props.renderInputPanel(false);
      props.renderEditPanel(false);
      props.renderProfilePanel(true);
      props.renderFeedbackPanel(false);
      props.renderSelectedCallerTunePanel(false);
    }
    if (mobile && props.callerTuneListPanel) {
      props.renderInputPanel(false);
      props.renderEditPanel(false);
      props.renderProfilePanel(true);
      props.renderFeedbackPanel(false);
      props.renderSelectedCallerTunePanel(false);
    }

    if (mobile && props.showSubscriptionPanel) {
      props.renderInputPanel(false);
      props.renderEditPanel(false);
      props.renderProfilePanel(true);
      props.renderFeedbackPanel(false);
      props.renderSelectedCallerTunePanel(false);
      props.renderSubscriptionPanel(false);
    }
  };

  return (
    <Grid className={classes.arrowStyle}>
      <ArrowBackIcon onClick={handleBackRoute} />
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    editPanel: state.ui.showEditPanel,
    inputPanel: state.ui.showInputPanel,
    profilePanel: state.ui.showProfilePanel,
    feedbackPanel: state.ui.showFeedbackPanel,
    callerTuneListPanel: state.ui.showSelectedCallertunePanel,
    showSubscriptionPanel: state.ui.showSubscriptionPanel,
  };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({
  renderEditPanel,
  renderInputPanel,
  renderProfilePanel,
  renderFeedbackPanel,
  renderSelectedCallerTunePanel,
  renderSubscriptionPanel
  // updateField: (form) => dispatch(reset(form)),
}, dispatch);


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BackArrow));
