/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { Card, CardContent, makeStyles, Button, useMediaQuery } from '@material-ui/core';
import PremiumIcon from '@assets/images/PremiumIcon.svg';
import { getSubscriptionDetails } from '../../reducers/payment';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        maxWidth: '90%',
        margin: '0 auto',
        borderRadius: 15,
        background: '#242424',
        marginBottom: '10px',
        [theme.breakpoints.up('sm')]: {
            minWidth: '60%',
        },
        height: '100px'
    },
    details: {
        display: 'flex',
        flexDirection: 'row',
        background: '#242424',

        width: '100%',
        justifyContent: 'space-around',
        alignItems: 'center',
        background: `linear-gradient(to right,#fff,${theme.palette.selectedTxtClr})`,

    },
    image: {
        width: 95,
        height: '90%',
        borderRadius: 15,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
    },
    iconStyle: {
        color: theme.palette.selectedTxtClr,
        width: '1.8em',
        height: '1.8em',
    },
}));

const SubscriptioData = [
    {
        heading: ' My Active Plan',
        Date: '07/01/2022',
        Expire_Date: '07/02/2022',
        isSubscribed: true

    },
    {
        heading: ' My Active Plan',
        Date: '07/01/2022',
        Expire_Date: '07/02/2022',
        isSubscribed: false
    },
    {
        heading: ' My Active Plan',
        Date: '07/01/2022',
        Expire_Date: '07/02/2022',
        isSubscribed: false
    },
]

const CardSubscription = (props) => {
    const classes = useStyles();
    const mobileView = useMediaQuery('(max-width:1024px)');

    useEffect(() => {
        props.getSubscriptionDetails();
    }, [])

    return (
        <>
            {props.subscriptionData?.responseList && props.subscriptionData.responseList.length > 0 && props.subscriptionData.responseList.map((subscriptiondata) => {
                return (
                    <Card className={classes.root}>
                        <div className={classes.details}>
                            <CardContent>
                                <div style={{ fontSize: '0.9375rem',fontFamily:'SegoeUIBold !important' }}><b>My Active Plan</b></div>
                                <div style={{ fontSize: '0.75rem' }}>Date : {moment(new Date(subscriptiondata.subscriptionTime)).format('DD/MM/YYYY hh:mm:ss a')}</div>
                                <div style={{ fontSize: '0.75rem' }}>Expire Date : {moment(new Date(subscriptiondata.expiryTime)).format('DD/MM/YYYY hh:mm:ss a')}</div>
                            </CardContent>
                            <div>
                                <img className={classes.iconStyle} src={PremiumIcon} alt='PremiumIcon' />  
                            </div>
                        </div>
                    </Card>

                )

            })}
            {(!props.loading && (!props.subscriptionData || !props.subscriptionData.responseList || props.subscriptionData.responseList == 0)) && (
                <div style={{ color: '#f44336', textAlign: 'center' }}>
                    No subscriptions found 
                </div>
            )}

        </>

    );
};

const mapStateToProps = (state) => {
    return {
        subscriptionData: state.payment.subscriptionData,
        loading: state.payment.loading
    };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({
    getSubscriptionDetails
},
    dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardSubscription));
